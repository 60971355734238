// Import Fuse core library
@import "@fuse/scss/core";

// Import app.theme.scss
@import "app/app.theme";

@media print {
    .nonprint {
        display: none;
    }
}

// Mat-table style
.mat-table {
    display: block;
    border-radius: 7px;
}

.mat-row,
.mat-header-row {
    display: flex;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-color: rgb(146, 146, 146);
    align-items: center;
    min-height: 48px;
    padding: 0 24px;
}

.mat-row:last-child {
    border-bottom-width: 0;
}

.mat-cell,
.mat-header-cell {
    flex: 1;
    overflow: hidden;
    word-wrap: break-word;
}

.title-cell {
    padding-left: 24px;
    align-content: center;

    h3 {
        font-weight: bolder;
        display: inline-block;
    }

    mat-icon {
        margin-left: 10px;
        font-size: 14px;
    }
}

p.error {
    color: red;
}